import React from 'react';
import Footer from './components/index/Footer.jsx'; 
import About from './components/vps/AboutVPS.jsx'; 
import FAQ from './components/vps/FAQSectionVPS.jsx'; 
import Block from './components/vps/Vpsblock.jsx'; 
import Block2 from './components/vps/SistemasOperacionais.jsx' 

const MinecraftPage = () => {
  return (
    <div>
      <Block />
      <Block2 />
      <About />
      <FAQ />
      <Footer />
    </div>
  );
};

export default MinecraftPage;
