import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MinecraftPage = () => {
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState('br');
  const [selectedProcessor, setSelectedProcessor] = useState('Ryzen 9900');
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const planData = {
    plan1: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '2GB RAM / 2vCores',
      oldPrice: 'R$30,00',
      newPrice: 'R$23,99/mês',
      cpu: '2vCPU [5.8Ghz]',
      memory: '4GB RAM DDR5',
      storage: '15GB SSD NVMe',
      serversAdd: 'Sem servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-01',
      location: 'br',
      processorType: 'Ryzen 9900',
      image: 'https://minecraft.wiki/images/thumb/Block_of_Coal_JE3_BE2.png/150px-Block_of_Coal_JE3_BE2.png?ed300'
    },
    plan2: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '4GB RAM / 3vCores',
      oldPrice: 'R$60,00',
      newPrice: 'R$47,99/mês',
      cpu: '3vCPU [5.8Ghz]',
      memory: '4GB RAM DDR5',
      storage: '30GB SSD NVMe',
      serversAdd: 'Até +1 servidor adicional',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-02',
      location: 'br', 
      processorType: 'Ryzen 9900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Lapis_Lazuli_JE3_BE3.png/150px-Block_of_Lapis_Lazuli_JE3_BE3.png?298ce' 
    },
    plan3: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '8GB RAM / 5vCores',
      oldPrice: 'R$120,00',
      newPrice: 'R$95,99/mês',
      cpu: '5vCPU [5.8Ghz]',
      memory: '8GB RAM DDR5',
      storage: '60GB SSD NVMe',
      serversAdd: 'Até +2 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-04',
      location: 'br',
      processorType: 'Ryzen 9900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Iron_JE4_BE3.png/150px-Block_of_Iron_JE4_BE3.png?18948'
    },
    plan4: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '12GB RAM / 7vCores',
      oldPrice: 'R$180,00',
      newPrice: 'R$143,99/mês',
      cpu: '7vCPU [5.8Ghz]',
      memory: '12GB RAM DDR5',
      storage: '90GB SSD NVMe',
      serversAdd: 'Até +3 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-06',
      location: 'br', 
      processorType: 'Ryzen 9900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Gold_JE6_BE3.png/150px-Block_of_Gold_JE6_BE3.png?09478' 
    },
    plan5: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '16GB RAM / 9vCores',
      oldPrice: 'R$240,00',
      newPrice: 'R$191,99/mês',
      cpu: '9vCPU [5.8Ghz]',
      memory: '16GB RAM DDR5',
      storage: '120GB SSD NVMe',
      serversAdd: 'Até +4 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-08',
      location: 'br', 
      processorType: 'Ryzen 9900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Emerald_JE4_BE3.png/150px-Block_of_Emerald_JE4_BE3.png?d5a3c' 
    },
    plan6: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '24GB RAM / 14vCores',
      oldPrice: 'R$360,00',
      newPrice: 'R$287,99/mês',
      cpu: '14vCPU [5.8Ghz]',
      memory: '24GB RAM DDR5',
      storage: '180GB SSD NVMe',
      serversAdd: 'Até +6 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-10',
      location: 'br', 
      processorType: 'Ryzen 9900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Diamond_JE5_BE3.png/150px-Block_of_Diamond_JE5_BE3.png?542ee' 
    },
    plan7: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '32GB RAM / 16vCores',
      oldPrice: 'R$480,00',
      newPrice: 'R$383,99/mês',
      cpu: '16vCPU [5.8Ghz]',
      memory: '32GB RAM DDR5',
      storage: '240GB SSD NVMe',
      serversAdd: 'Até +8 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-11',
      location: 'br',
      processorType: 'Ryzen 9900',
      image: 'https://minecraft.wiki/images/thumb/Block_of_Netherite_JE1_BE1.png/150px-Block_of_Netherite_JE1_BE1.png?7e0a3' 
    },
    plan8: {
      processor: 'AMD Ryzen 9 9900x/9950x',
      name: '48GB RAM / 20vCores',
      oldPrice: 'R$720,00',
      newPrice: 'R$575,99/mês',
      cpu: '20vCPU [5.8Ghz]',
      memory: '48GB RAM DDR5',
      storage: '360GB SSD NVMe',
      serversAdd: 'Até +10 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-9950x/minecraft-9950x-br-12',
      location: 'br', 
      processorType: 'Ryzen 9900', 
      image: 'https://minecraft.wiki/images/thumb/Bedrock_JE2_BE2.png/150px-Bedrock_JE2_BE2.png?feb6c' 
    },
    plan9: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '2GB RAM / 2vCores',
      oldPrice: 'R$24,00',
      newPrice: 'R$19,99/mês',
      cpu: '2vCPU [5.7Ghz]',
      memory: '4GB RAM DDR5',
      storage: '15GB SSD NVMe',
      serversAdd: 'Sem servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-br-01',
      location: 'br',
      processorType: 'Ryzen 7900',
      image: 'https://minecraft.wiki/images/thumb/Block_of_Coal_JE3_BE2.png/150px-Block_of_Coal_JE3_BE2.png?ed300'
    },
    plan10: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '4GB RAM / 3vCores',
      oldPrice: 'R$48,00',
      newPrice: 'R$39,99/mês',
      cpu: '3vCPU [5.7Ghz]',
      memory: '4GB RAM DDR5',
      storage: '30GB SSD NVMe',
      serversAdd: 'Até +1 servidor adicional',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-br-02',
      location: 'br', 
      processorType: 'Ryzen 7900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Lapis_Lazuli_JE3_BE3.png/150px-Block_of_Lapis_Lazuli_JE3_BE3.png?298ce' 
    },
    plan11: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '8GB RAM / 5vCores',
      oldPrice: 'R$96,00',
      newPrice: 'R$79,99/mês',
      cpu: '5vCPU [5.7Ghz]',
      memory: '8GB RAM DDR5',
      storage: '60GB SSD NVMe',
      serversAdd: 'Até +2 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-br-04',
      location: 'br',
      processorType: 'Ryzen 7900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Iron_JE4_BE3.png/150px-Block_of_Iron_JE4_BE3.png?18948'
    },
    plan12: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '12GB RAM / 7vCores',
      oldPrice: 'R$144,00',
      newPrice: 'R$119,99/mês',
      cpu: '7vCPU [5.7Ghz]',
      memory: '12GB RAM DDR5',
      storage: '90GB SSD NVMe',
      serversAdd: 'Até +3 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-br-06',
      location: 'br', 
      processorType: 'Ryzen 7900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Gold_JE6_BE3.png/150px-Block_of_Gold_JE6_BE3.png?09478' 
    },
    plan13: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '16GB RAM / 9vCores',
      oldPrice: 'R$192,00',
      newPrice: 'R$159,99/mês',
      cpu: '9vCPU [5.7Ghz]',
      memory: '16GB RAM DDR5',
      storage: '120GB SSD NVMe',
      serversAdd: 'Até +4 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-br-08',
      location: 'br', 
      processorType: 'Ryzen 7900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Emerald_JE4_BE3.png/150px-Block_of_Emerald_JE4_BE3.png?d5a3c' 
    },
    plan14: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '24GB RAM / 14vCores',
      oldPrice: 'R$288,00',
      newPrice: 'R$239,99/mês',
      cpu: '14vCPU [5.7Ghz]',
      memory: '24GB RAM DDR5',
      storage: '180GB SSD NVMe',
      serversAdd: 'Até +6 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-br-10',
      location: 'br', 
      processorType: 'Ryzen 7900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Diamond_JE5_BE3.png/150px-Block_of_Diamond_JE5_BE3.png?542ee' 
    },
    plan15: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '32GB RAM / 16vCores',
      oldPrice: 'R$384,00',
      newPrice: 'R$319,99/mês',
      cpu: '16vCPU [5.7Ghz]',
      memory: '32GB RAM DDR5',
      storage: '240GB SSD NVMe',
      serversAdd: 'Até +8 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-br-11',
      location: 'br',
      processorType: 'Ryzen 7900',
      image: 'https://minecraft.wiki/images/thumb/Block_of_Netherite_JE1_BE1.png/150px-Block_of_Netherite_JE1_BE1.png?7e0a3' 
    },
    plan16: {
      processor: 'AMD Ryzen 9 7900x/7950x',
      name: '48GB RAM / 20vCores',
      oldPrice: 'R$576,00',
      newPrice: 'R$479,99/mês',
      cpu: '20vCPU [5.7Ghz]',
      memory: '48GB RAM DDR5',
      storage: '360GB SSD NVMe',
      serversAdd: 'Até +10 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-7950x/minecraft-r9-br-12',
      location: 'br', 
      processorType: 'Ryzen 7900', 
      image: 'https://minecraft.wiki/images/thumb/Bedrock_JE2_BE2.png/150px-Bedrock_JE2_BE2.png?feb6c' 
    },
    plan17: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '2GB RAM / 2vCores',
      oldPrice: 'R$20,00',
      newPrice: 'R$15,99/mês',
      cpu: '2vCPU [4.8Ghz]',
      memory: '2GB RAM DDR4',
      storage: '15GB SSD NVMe',
      serversAdd: 'Sem servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-01',
      location: 'br',
      processorType: 'Ryzen 5900',
      image: 'https://minecraft.wiki/images/thumb/Block_of_Coal_JE3_BE2.png/150px-Block_of_Coal_JE3_BE2.png?ed300'
    },
    plan18: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '4GB RAM / 3vCores',
      oldPrice: 'R$40,00',
      newPrice: 'R$31,99/mês',
      cpu: '3vCPU [4.8Ghz]',
      memory: '4GB RAM DDR4',
      storage: '30GB SSD NVMe',
      serversAdd: 'Sem servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-02',
      location: 'br', 
      processorType: 'Ryzen 5900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Lapis_Lazuli_JE3_BE3.png/150px-Block_of_Lapis_Lazuli_JE3_BE3.png?298ce' 
    },
    plan19: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '8GB RAM / 4vCores',
      oldPrice: 'R$80,00',
      newPrice: 'R$63,99/mês',
      cpu: '4vCPU [4.8Ghz]',
      memory: '8GB RAM DDR4',
      storage: '60GB SSD NVMe',
      serversAdd: 'Até +1 servidor adicional',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-04',
      location: 'br',
      processorType: 'Ryzen 5900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Iron_JE4_BE3.png/150px-Block_of_Iron_JE4_BE3.png?18948'
    },
    plan20: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '12GB RAM / 5vCores',
      oldPrice: 'R$120,00',
      newPrice: 'R$95,99/mês',
      cpu: '5vCPU [4.8Ghz]',
      memory: '12GB RAM DDR4',
      storage: '90GB SSD NVMe',
      serversAdd: 'Até +2 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-06',
      location: 'br',
      processorType: 'Ryzen 5900',
      image: 'https://minecraft.wiki/images/thumb/Block_of_Gold_JE6_BE3.png/150px-Block_of_Gold_JE6_BE3.png?09478' 
    },
    plan21: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '16GB RAM / 6vCores',
      oldPrice: 'R$160,00',
      newPrice: 'R$127,99/mês',
      cpu: '6vCPU [4.8Ghz]',
      memory: '16GB RAM DDR4',
      storage: '120GB SSD NVMe',
      serversAdd: 'Até +3 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-08',
      location: 'br', 
      processorType: 'Ryzen 5900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Emerald_JE4_BE3.png/150px-Block_of_Emerald_JE4_BE3.png?d5a3c' 
    },
    plan22: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '24GB RAM / 8vCores',
      oldPrice: 'R$240,00',
      newPrice: 'R$191,99/mês',
      cpu: '8vCPU [4.8Ghz]',
      memory: '24GB RAM DDR4',
      storage: '150GB SSD NVMe',
      serversAdd: 'Até +5 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-10',
      location: 'br', 
      processorType: 'Ryzen 5900', 
      image: 'https://minecraft.wiki/images/thumb/Block_of_Diamond_JE5_BE3.png/150px-Block_of_Diamond_JE5_BE3.png?542ee' 
    },
    plan23: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '32GB RAM / 10vCores',
      oldPrice: 'R$320,00',
      newPrice: 'R$255,99/mês',
      cpu: '10vCPU [4.8Ghz]',
      memory: '32GB RAM DDR4',
      storage: '180GB SSD NVMe',
      serversAdd: 'Até +7 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-11',
      location: 'br',
      processorType: 'Ryzen 5900',
      image: 'https://minecraft.wiki/images/thumb/Block_of_Netherite_JE1_BE1.png/150px-Block_of_Netherite_JE1_BE1.png?7e0a3' 
    },
    plan24: {
      processor: 'AMD Ryzen 9 5900x/5950x',
      name: '48GB RAM / 12vCores',
      oldPrice: 'R$480,00',
      newPrice: 'R$383,99/mês',
      cpu: '12vCPU [4.8Ghz]',
      memory: '48GB RAM DDR4',
      storage: '240GB SSD NVMe',
      serversAdd: 'Até +8 servidores adicionais',
      protecao: 'Proteção Anti-DDOs MagicTransit',
      ipsdedic: 'Porta padrão por +R$15,00',
      cupom: '15% de desconto recorrente',
      link: 'https://financeiro.magnohost.com.br/index.php/store/minecraft-br-ryzen-5/minecraft-5950x-br-12',
      location: 'br', 
      processorType: 'Ryzen 5900', 
      image: 'https://minecraft.wiki/images/thumb/Bedrock_JE2_BE2.png/150px-Bedrock_JE2_BE2.png?feb6c' 
    },
  };

  const fetchPlans = () => {
    return new Promise((resolve, reject) => {
      const filteredPlans = Object.keys(planData).filter(planId => {
        const plan = planData[planId];
        return plan.location === selectedLocation && plan.processorType === selectedProcessor;
      });

      if (filteredPlans.length > 0) {
        resolve(filteredPlans);
      } else {
        reject(new Error('Nenhum plano encontrado.'));
      }
    });
  };

  const updateFilteredPlans = () => {
    if (!isLoading) {
      setIsLoading(true);
      toast.loading('Procurando planos...', { toastId: 'loading-toast' });

      fetchPlans()
        .then(filtered => {
          setFilteredPlans(filtered);
          toast.update('loading-toast', {
            render: 'Planos carregados com sucesso!',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        })
        .catch(error => {
          setFilteredPlans([]); // Limpa os planos filtrados
          toast.update('loading-toast', {
            render: error.message,
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const handleProcessorChange = (processor) => {
    setSelectedProcessor(processor);
  };

  useEffect(() => {
    updateFilteredPlans();
  }, [selectedLocation, selectedProcessor]);

  const handleSelectPlan = (planId) => {
    setSelectedPlanId(planId);
  };

  const selectedPlan = selectedPlanId ? planData[selectedPlanId] : null;

  return (
    <div className="custom-container">
      <ToastContainer />
      <section className="custom-step custom-step-1">
        <h1 className="custom-title2">Escolha uma localização para seu servidor:</h1>
        <div className="custom-btn-group">
          <button 
            className={`custom-btn custom-location ${selectedLocation === 'br' ? 'active' : ''}`}
            onClick={() => handleLocationChange('br')}
          >
            <img
              className="img-flag"
              src="https://magnohost-web-sigma.vercel.app/_next/image?url=%2Fflags%2Fbrazil.png&w=48&q=75"
              alt="Bandeira do Brasil"
            />
            <span>São Paulo</span>
          </button>
        </div>
      </section>
      <section className="custom-step custom-step-2">
        <h1 className="custom-title3">Escolha seu processador:</h1>
        <div className="custom-btn-group2">
        <button 
            className={`custom-btn custom-processor ${selectedProcessor === 'Ryzen 9900' ? 'active' : ''}`}
            onClick={() => handleProcessorChange('Ryzen 9900')}
          >
            <img
              src="https://magnohost-web-sigma.vercel.app/_next/image?url=%2Fimages%2Famd-branco4.png&w=128&q=75"
              alt="AMD Logo"
              className="img-logo2"
            />
            <div>Ryzen 9 9950x</div>
          </button>
          <button 
            className={`custom-btn custom-processor ${selectedProcessor === 'Ryzen 7900' ? 'active' : ''}`}
            onClick={() => handleProcessorChange('Ryzen 7900')}
          >
            <img
              src="https://magnohost-web-sigma.vercel.app/_next/image?url=%2Fimages%2Famd-branco4.png&w=128&q=75"
              alt="AMD Logo"
              className="img-logo2"
            />
            <div>Ryzen 9 7950x</div>
          </button>
          <button 
            className={`custom-btn custom-processor ${selectedProcessor === 'Ryzen 5900' ? 'active' : ''}`}
            onClick={() => handleProcessorChange('Ryzen 5900')}
          >
            <img
              src="https://magnohost-web-sigma.vercel.app/_next/image?url=%2Fimages%2Famd-branco4.png&w=128&q=75"
              alt="AMD Logo"
              className="img-logo2"
            />
            <div>Ryzen 9 5950x</div>
          </button>
        </div>
      </section>
      <div className="custom-flex-container">
        <section className="custom-step custom-step-3">
          <h1 className="custom-title">Escolha o plano ideal para seu servidor</h1>
          <div className="custom-card-group">
            {filteredPlans.map(planId => (
              <div
                key={planId}
                className={`custom-card ${selectedPlanId === planId ? 'selected' : ''}`}
                onClick={() => handleSelectPlan(planId)}
              >
                <img
                  src={planData[planId].image}
                  alt={planData[planId].cpu}
                  className="custom-card-img"
                />
                <div className="custom-card-content">
                  <h2 className="custom-card-title">{planData[planId].name}</h2>
                  <p className="custom-old-price">{planData[planId].oldPrice}</p>
                  <p className="custom-price">{planData[planId].newPrice}</p>
                  <p className="custom-details-link">Clique para ver os detalhes</p>
                </div>
              </div>
            ))}
          </div>

          <div className="custom-flex-container" style={{ visibility: "hidden" }}>
            <div className="custom-column-left">
              <div className="custom-card" onclick="updatePlanDetails('plan1')">
                {/* Card 1 */}
              </div>
              <div className="custom-card" onclick="updatePlanDetails('plan2')">
                {/* Card 2 */}
              </div>
              <div className="custom-card" onclick="updatePlanDetails('plan3')">
                {/* Card 3 */}
              </div>
              {/* Adicione mais cards aqui */}
            </div>
            <div className="custom-column-right">
              <div className="custom-card" onclick="updatePlanDetails('plan4')">
                {/* Card 4 */}
              </div>
              <div className="custom-card" onclick="updatePlanDetails('plan5')">
                {/* Card 5 */}
              </div>
              <div className="custom-card" onclick="updatePlanDetails('plan6')">
                {/* Card 6 */}
              </div>
              {/* Adicione mais cards aqui */}
            </div>
          </div>
        </section>
        <aside className={`custom-plan-details ${selectedPlan ? 'active' : ''}`}>
        <h2 className="custom-details-title">Detalhes do plano</h2>
          {selectedPlan ? (
            <div className="custom-plan-info" style={{ display: 'block' }}>
              <ul className="custom-list-group">
                <li className="custom-list-item">
                  <i className="fas fa-microchip"></i> {selectedPlan.processor}
                </li>
                <li className="custom-list-item">
                  <i className="fas fa-tachometer-alt"></i> {selectedPlan.cpu}
                </li>
                <li className="custom-list-item">
                  <i className="fas fa-memory"></i> {selectedPlan.memory}
                </li>
                <li className="custom-list-item">
                  <i className="fas fa-hdd"></i> {selectedPlan.storage}
                </li>
                <li className="custom-list-item">
                  <i className="fa-solid fa-server"></i> {selectedPlan.serversAdd}
                </li>
                <li className="custom-list-item">
                  <i className="fa-solid fa-shield-check"></i> {selectedPlan.protecao}
                </li>
                <li className="custom-list-item">
                  <i className="fa-solid fa-globe"></i> {selectedPlan.ipsdedic}
                </li>
                <li className="custom-list-item">
                  <i className="fa-solid fa-percent"></i> {selectedPlan.cupom}
                </li>
              </ul>
              <div className="custom-price-box">
                <span className="custom-old-price">{selectedPlan.oldPrice}</span>
                <span className="custom-new-price">{selectedPlan.newPrice}</span>
                <a href={selectedPlan.link} className="custom-btn custom-purchase-btn">
                  Contratar
                </a>
              </div>
            </div>
          ) : (
            <p className="custom-no-info">Sem informações</p>
          )}
        </aside>
      </div>
      <p style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
            color: "#2f1c6a",
            fontSize: 17,
            alignContent: "center",
            alignItems: "center"
        }}>
            Para visualizar mais opções de planos, acesse nosso financeiro
            <a href="https://financeiro.magnohost.com.br/store/hospedagem-de-minecraft-br" style={{
                fontWeight: 600,
                marginLeft: 5,
                textDecoration: "underline"
            }}>
                clicando aqui!
            </a>
        </p>
    </div>
  );
};

export default MinecraftPage;
