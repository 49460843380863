import React from 'react';

const Terms = () => {
  return (
    <>
  <div
    className="container"
    style={{
      marginTop: 280,
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    <h2 style={{ color: "#7a0cf2", fontSize: 36, fontWeight: 700 }}>
      Termos de Serviço da MagnoHost
    </h2>
    <p>
      Ao contratar um de nossos serviços você concorda com os termos de serviço.
    </p>
  </div>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: 120 }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Termos de Serviço:
        </h2>
      </div>
      <p>
        <br />
        <strong>1. Aceitação dos Termos</strong><br></br>
        Ao utilizar os serviços da Magno Host, você concorda com estes Termos de
        Serviço. Se você não concordar com estes termos, por favor, não utilize nossos serviços.
        <br />
        <br />
        <strong>2. Registro e Segurança</strong><br></br>
        Como parte do processo de registro, você criará uma senha e uma conta. Você é responsável por manter a confidencialidade de sua senha e da sua conta, sendo totalmente responsável por todas as atividades que ocorrem sob sua senha ou conta.
        <br />
        <br />
        <strong>3. Uso do Serviço</strong><br></br>
        Você concorda em utilizar nossos serviços de maneira legal e ética, não empregando-os para atividades ilegais, fraudulentas, maliciosas ou qualquer outra que possa prejudicar a operação de nossos sistemas ou a segurança de terceiros.
        <br />
        <br />
        <strong>4. Pagamento</strong><br></br>
        Os detalhes sobre preços e planos de pagamento estão disponíveis em nosso site. O pagamento é exigido antecipadamente para todos os serviços, e o tempo de aprovação pode variar de acordo com a forma de pagamento escolhida. Quaisquer taxas adicionais ou custos extras, como impostos ou taxas bancárias, serão detalhados no momento da compra.
        <br />
        <br />
        <strong>5. Uptime SLA</strong><br></br>
        A MagnoHost se compromete a fornecer 99,97% de uptime para todos os nossos serviços. Caso o downtime seja superior a 0,03% do tempo total de serviço mensal, a MagnoHost oferecerá uma compensação equivalente a até 2 vezes o tempo perdido nos seus serviços.
        <br />
        <br />
        <strong>6. Limitação de Responsabilidade</strong><br></br>
        A MagnoHost não será responsável por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou exemplares resultantes do uso ou da incapacidade de usar nossos serviços. A MagnoHost não será responsável por falhas que ocorram devido a fatores externos, como problemas de rede do cliente, falhas no hardware ou software não fornecidos pela MagnoHost.
        <br />
        <br />
        <strong>7. Indenização</strong><br></br>
        Você concorda em indenizar e isentar a MagnoHost de qualquer reivindicação, perda, responsabilidade, custos ou danos decorrentes de ações legais ou demandas feitas por terceiros devido ao seu uso do serviço.
        <br />
        <br />
        <strong>8. Resolução de Disputas</strong><br></br>
        Qualquer disputa relacionada a estes Termos de Serviço será regida pelas leis da República Federativa do Brasil, sem levar em conta qualquer escolha de disposições legais conflitantes.
        <br />
        <br />
        <strong>9. Alterações nos Termos</strong><br></br>
        A MagnoHost reserva-se o direito de modificar estes Termos de Serviço a qualquer momento. As alterações entrarão em vigor imediatamente após a publicação no nosso site. Recomendamos que você revise os Termos regularmente para garantir que está ciente de quaisquer mudanças.
        <br />
      </p>
    </div>
  </section>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: "-50px" }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Cláusulas Especiais:
        </h2>
      </div>
      <p>
        <br />
        <strong>1.0 Uso Indevido do Serviço</strong><br></br>
        A MagnoHost reserva-se o direito de suspender ou encerrar qualquer serviço que seja utilizado para fins ilícitos, fraudulentos ou que violem as leis brasileiras, como por exemplo:<br></br>
        <br />- Atividades de pirataria.
        <br />- Ataques de negação de serviço (DDoS).
        <br />- Envio de SPAM.
        <br />- Qualquer outro comportamento que prejudique a rede, servidores ou sistemas de terceiros.
        <br />
        <br />
        <strong>1.1 Força Maior</strong><br></br>
        A MagnoHost não será responsável por falhas ou interrupções nos serviços causadas por eventos de força maior, incluindo, mas não se limitando a, desastres naturais, ataques cibernéticos, falhas de fornecimento de energia elétrica, atos de terrorismo ou qualquer outra situação fora do controle razoável da empresa.
        <br />
        <br />
        <strong>1.2 Definição de "Serviço"</strong><br></br>
        Para efeitos deste termo, o serviço refere-se à provisão de hospedagem, suporte técnico, gerenciamento de servidores e qualquer outro serviço oferecido pela MagnoHost em seu portfólio.
        <br />
        <br />
        <strong>1.3 Disposições Finais</strong><br></br>
        Esses Termos de Serviço são regidos pelas leis da República Federativa do Brasil. Qualquer disputa que surja em razão da interpretação ou execução desses Termos será resolvida pelo foro da cidade de São Paulo, Estado de São Paulo, com renúncia expressa a qualquer outro, por mais privilegiado que seja.
        <br />
      </p>
    </div>
  </section>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: "-50px" }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Redução CPU em Casos de Uso Excessivo (Hospedagens de Jogos Compartilhadas):
        </h2>
      </div>
      <p>
        <br />
        <strong></strong><br></br>
        A MagnoHost oferece planos de hospedagem compartilhada de Jogos, onde a CPU é compartilhada entre diversos clientes, enquanto os demais recursos, como memória e armazenamento, podem ser utilizados de acordo com a necessidade de cada cliente sem limitações. Devido à natureza compartilhada da CPU nesses planos, a MagnoHost se reserva o direito de reduzir a alocação de CPU de qualquer cliente em até 50%, exclusivamente em planos de hospedagens compartilhadas de Jogos, quando o uso de CPU exceder os limites normais por longos períodos e/ou impactar a performance de outros clientes na mesma máquina.
        <br />
        <br />
        <strong>1.0 Uso Excessivo</strong><br></br>
        A redução será aplicada caso o uso de CPU de um servidor ultrapasse um limite predeterminado e o consumo excessivo prejudique a estabilidade do ambiente compartilhado, afetando o desempenho de outros servidores hospedados na mesma máquina.
        <br />
        <br />
        <strong>1.1 Execução sem Aviso Prévio</strong><br></br>
        A MagnoHost poderá aplicar a redução de CPU imediatamente, sem aviso prévio, nos casos em que o uso excessivo de CPU esteja causando impacto negativo para outros clientes na mesma máquina.
        <br />
        <br />
        <strong>1.2 Garantia de Funcionamento Básico</strong><br></br>
        Mesmo com a redução de CPU, a MagnoHost se compromete a garantir que o servidor do cliente continue funcionando adequadamente, com recursos suficientes para manter o desempenho básico do jogo. A redução de CPU não será realizada de maneira que comprometa a funcionalidade essencial do servidor, como o início e a execução das operações principais do jogo.
        <br />
        <br />
        <strong>1.3 Restabelecimento dos Recursos</strong><br></br>
        Quando o uso de CPU do cliente retornar aos níveis aceitáveis e não causar mais impacto nos demais clientes, a alocação de recursos será restaurada ao seu estado original.<br /><br />
        A MagnoHost se compromete a agir com transparência e sempre buscar a melhor experiência para todos os clientes, utilizando a limitação de recursos apenas quando necessário para preservar a qualidade do serviço para todos.
        <br />
      </p>
    </div>
  </section>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: "-50px" }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Política de Privacidade:
        </h2>
      </div>
      <p>
        <br />
        <strong>1.0 Informações que coletamos</strong><br></br>
        Podemos coletar informações pessoais que você nos fornece ao se registrar, como seu nome, endereço de e-mail, detalhes de pagamento, e outras informações necessárias para a prestação dos nossos serviços.
        <br />
        <br />
        <strong>1.1 Como usamos suas informações</strong><br></br>
        Usamos suas informações pessoais para fornecer e melhorar nossos serviços, processar pagamentos e gerenciar suas assinaturas. Também utilizamos seus dados para nos comunicarmos com você sobre atualizações, mudanças e promoções relacionadas aos nossos serviços. Além disso, podemos utilizar suas informações para cumprir com obrigações legais e regulatórias, conforme necessário.
        <br />
        <br />
        <strong>1.2 Compartilhamento de informações</strong><br></br>
        Não compartilhamos suas informações pessoais com terceiros, exceto nas situações em que isso é necessário para fornecer nossos serviços, como quando trabalhamos com processadores de pagamento ou parceiros de infraestrutura. Também podemos compartilhar suas informações quando for exigido por lei ou por autoridades competentes, ou no caso de fusão, aquisição ou venda de ativos da empresa.
        <br />
        <br />
        <strong>1.3 Segurança</strong><br></br>
        Estamos comprometidos em proteger suas informações pessoais. Implementamos medidas de segurança técnicas e administrativas para proteger suas informações contra acessos não autorizados, alterações, divulgação ou destruição. No entanto, nenhum método de transmissão de dados ou armazenamento eletrônico é 100% seguro.
        <br />
        <br />
        <strong>1.4 Cookies e Tecnologias de Rastreamento</strong><br></br>
        Utilizamos cookies e tecnologias similares para melhorar a sua experiência em nosso site e personalizar os conteúdos oferecidos. Os cookies nos ajudam a analisar o tráfego do site, lembrar suas preferências e oferecer uma experiência personalizada. Você pode optar por desativar os cookies em seu navegador, mas isso pode afetar a funcionalidade do site e a sua experiência de navegação.
        <br />
        <br />
        <strong>1.5 Alterações na Política de Privacidade</strong><br></br>
        Podemos atualizar nossa Política de Privacidade periodicamente para refletir mudanças em nossos serviços ou requisitos legais. Recomendamos que você revise esta política regularmente para se manter informado sobre como protegemos suas informações.
        <br />
        <br />
        <strong>1.6 Seus Direitos sobre os Dados Pessoais</strong><br></br>
        De acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD), você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais a qualquer momento. Caso deseje exercer esses direitos, por favor, entre em contato conosco através de nossos canais de suporte.
        <br />
      </p>
    </div>
  </section>
  <section
    className="dark:bg-dark bg-white py-[70px]"
    data-id="49838c9c-4825-44bf-a"
    style={{ marginTop: "-50px" }}
  >
    <div className="mx-auto px-4 sm:container" data-id="fd4dfb10-d594-4864-a">
      <div
        className="border-primary border-l-[5px] pl-5"
        data-id="63d9be15-19e4-46bf-8"
        style={{ borderColor: "#7a0cf2" }}
      >
        <h2
          style={{ color: "#2F1C6A" }}
          className="mb-2 text-2xl font-semibold dark:text-white"
          content-id="d730867c-0f23-4754-b"
          data-id="80df5f23-d120-44e7-8"
        >
          Política de Reembolso:
        </h2>
      </div>
      <p>
        <br />
        <strong>1.0 Cancelamento</strong><br></br>
        Você pode cancelar sua conta a qualquer momento diretamente na sua área do cliente, sem complicações adicionais.
        <br />
        <br />
        <strong>1.1 Direito de Arrependimento</strong><br></br>
        De acordo com o Art. 49 do Código de Defesa do Consumidor (Lei nº 8.078/1990), você pode solicitar o cancelamento de um serviço contratado dentro de até 7 (sete) dias corridos após a contratação.
        <br />
        <br />
        <strong>1.2 Reembolsos Proporcionais</strong><br></br>
        Caso o cliente tenha utilizado o serviço durante o período de arrependimento, a Magno Host realizará o reembolso considerando o valor proporcional dos dias restantes no contrato, descontando o período de uso do serviço. O cálculo será feito com base no valor diário do plano contratado.
        <br />Essa prática está amparada pelo Art. 51, IV do CDC, que veda o enriquecimento sem causa por parte do consumidor, e pelo princípio da equidade previsto no Art. 884 do Código Civil.
        <br />
        <br />
        <strong>1.3 Dados Falsos ou Inexatos</strong><br></br>
        O cliente é responsável por fornecer informações corretas, completas e verdadeiras no momento da contratação. Em caso de constatação de dados falsos ou inexatos, a Magno Host se reserva o direito de:
        <br /><br />- Bloquear o acesso ao serviço até que as informações sejam corrigidas.
        <br />- Negar o reembolso, mesmo dentro do prazo de arrependimento, com base no Art. 49 do CDC, dado que o cliente agiu em má-fé ao contratar o serviço utilizando dados inválidos ou fraudulentos.

        Este item também está amparado pelo Art. 422 do Código Civil, que estabelece o princípio da boa-fé contratual, e pelo Art. 187 do Código Civil, que trata do abuso de direito.
        <br />
        <br />
        <strong>1.4 Processamento de Reembolsos</strong><br></br>
        Para solicitar o reembolso, o cliente deve abrir um ticket na área do cliente, informando os seguintes dados:
        <br /><br />- Motivo da solicitação;
        <br />- Dados bancários para devolução do valor;
        <br />- Documentação adicional, caso necessário, para comprovar a identidade e evitar fraudes.

        <br /><br />A Magno Host se compromete a iniciar o processo de reembolso em até 72 (setenta e duas) horas após o recebimento da solicitação válida e a finalizar o processo no prazo máximo de 15 (quinze) dias úteis. Este prazo está alinhado ao Art. 4º, III do CDC, que exige atendimento eficiente e ágil ao consumidor.
        <br />
        <br />
        <strong>1.5 Limitações ao Direito de Arrependimento</strong><br></br>
        O direito ao arrependimento não será aplicável quando:
        <br /><br />- O serviço foi ativado e utilizado de forma parcial ou total, conforme o Art. 51, IV do CDC, para evitar prejuízo indevido ao fornecedor.
        <br />- O cliente forneceu informações falsas ou incompletas, mesmo que o serviço tenha sido ativado, em consonância com o Art. 422 do Código Civil e o Art. 187 do Código Civil (abuso de direito).
        <br />- O cliente deixou de disponibilizar informações ou dados necessários para a correta ativação do serviço, considerando que a não utilização ocorreu por culpa exclusiva do consumidor, conforme o Art. 49 do CDC.
        <br />
      </p>
    </div>
  </section>
</>
  );
};

export default Terms;